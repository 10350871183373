import { Box } from "@shopify/polaris";
import styled from "styled-components";

export const WideModalWrapper = styled.div`
  .Polaris-Modal-Dialog__Modal {
    max-width: 50rem;
    width: 50rem;
  }
`;

export const EmailPreviewWrapper = styled(Box)<{ modal?: boolean }>`
  border-top: ${({ modal }) => (!modal ? "var(--p-border-width-1) solid var(--p-color-border-subdued)" : "")};
  margin: ${({ modal }) => (!modal ? "20px 0" : "")};
  padding: ${({ modal }) => (!modal ? "20px 0" : "")};

  // Resetting outside Polaris / Primitive styles affecting emails
  .box {
    background-color: transparent;
    border-radius: 0px;
    box-shadow: none;
    color: inherit;
    margin-bottom: 0;
    padding: 0;
  }

  a[href] {
    pointer-events: none;
  }

  // Old Emails - Ensuring email fills card completely
  table.mw-100p,
  table.templateContainer {
    width: inherit !important;

    .pb-5 {
      padding-bottom: 5px !important;
    }
  }
`;

export const StyledEmailInputContainer = styled.div`
  border-bottom: "var(--p-border-width-1) solid var(--p-color-border-subdued)";
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
