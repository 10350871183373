import { IShopifyData } from "../../../../ShopifyData";
import { passwordlessEmailFrame } from "../../../components/passwordlessFrame";

export function createOneTimeCodeEmailSubject(shopifyData: IShopifyData, reqType: "login" | "signup") {
  return `🔐 Here's your one-time code for ${shopifyData.name} ${reqType}`;
}
export function createOneTimeCodeEmailBody(shopifyData: IShopifyData) {
  return passwordlessEmailFrame(`
  <center>
      <table
        style="
          border-collapse: collapse;
          mso-table-lspace: 0;
          mso-table-rspace: 0;
          padding: 0;
          background-color: #eee;
          height: 100%;
          margin: 0 auto;
          width: 100%;
          background-color: <%- theming.colors.backgroundColor %>
        "
        align="center"
        border="0"
        cellpadding="0"
        cellspacing="0"
        id="bodyTable"
        width="100%"
      >
        <tbody>
          <tr>
            <td
              align="center"
              id="bodyCell"
              valign="top"
              style="
                border-collapse: collapse;
                mso-table-lspace: 0;
                mso-table-rspace: 0;
                padding-top: 50px;
                padding-left: 20px;
                padding-bottom: 20px;
                padding-right: 20px;
                border-top: 0;
                height: 100%;
                margin: 0;
                width: 100%;
              "
            >
              <table
                border="0"
                cellpadding="0"
                cellspacing="0"
                class="templateContainer"
                width="600"
                style="
                  border-collapse: collapse;
                  mso-table-lspace: 0;
                  mso-table-rspace: 0;
                  border: 0 none #aaa;
                  background-color: <%- theming.colors.backgroundColor %>;
                  border-radius: 0;
                "
              >
                <tbody>
                  <tr>
                    <td
                      class="templateContainerInner"
                      style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0; padding: 0"
                    >
                      <table
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                      >
                        <tr>
                          <td
                            align="center"
                            valign="top"
                            style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              class="templateRow"
                              width="100%"
                              style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    class="rowContainer kmFloatLeft"
                                    valign="top"
                                    style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                                  >
                                    <table
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      class="kmTextBlock"
                                      width="100%"
                                      style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                                    >
                                      <tbody class="kmTextBlockOuter">
                                        <tr>
                                          <td
                                            class="kmTextBlockInner"
                                            valign="top"
                                            style="
                                              border-collapse: collapse;
                                              mso-table-lspace: 0;
                                              mso-table-rspace: 0;
                                            "
                                          >
                                            <table
                                              align="left"
                                              border="0"
                                              cellpadding="0"
                                              cellspacing="0"
                                              class="kmTextContentContainer"
                                              width="100%"
                                              style="
                                                border-collapse: collapse;
                                                mso-table-lspace: 0;
                                                mso-table-rspace: 0;
                                              "
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    class="kmTextContent"
                                                    valign="top"
                                                    style="
                                                      border-collapse: collapse;
                                                      mso-table-lspace: 0;
                                                      mso-table-rspace: 0;
                                                      color: #222;
                                                      font-family: Arial;
                                                      font-size: 16px;
                                                      line-height: 19px;
                                                      text-align: center;
                                                      padding-top: 60px;
                                                      padding-left: 30px;
                                                      color:<%- theming.colors.primaryTextColor %>;
                                                    "
                                                  >
                                                    <p>
                                                      Hi <%- customer.firstName %>, here's your one-time login code for ${shopifyData.customer_email}. <br/> Copy and paste your code to securely log in:
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      class="kmTableBlock kmTableMobile"
                                      width="100%"
                                      style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                                    >
                                      <tbody class="kmTableBlockOuter">
                                        <tr>
                                          <td class="kmTableBlockInner" valign="top">
                                            <table
                                              align="left"
                                              border="0"
                                              cellpadding="0"
                                              cellspacing="0"
                                              class="kmTextContentContainer"
                                              style="
                                                border-collapse: collapse;
                                                mso-table-lspace: 0;
                                                mso-table-rspace: 0;
                                                table-layout: fixed;
                                              "
                                              width="100%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    class="kmTextContent"
                                                    style="
                                                      border-collapse: collapse;
                                                      mso-table-lspace: 0;
                                                      mso-table-rspace: 0;
                                                      table-layout: fixed;
                                                      color: #222;
                                                      font-family: Arial;
                                                      font-size: 14px;
                                                      letter-spacing: 0;
                                                      line-height: 1.3;
                                                      max-width: 100%;
                                                      padding-top: 30px;
                                                      text-align: center;
                                                      word-wrap: break-word;
                                                      padding-bottom: 0;
                                                      padding-left: 60px;
                                                      padding-right: 60px;
                                                    "
                                                    valign="top"
                                                  >
                                                    <div
                                                      data-pm-slice="1 1 []"
                                                      style="caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0)"
                                                    >
                                                      <span style="font-size: 42px"
                                                        ><span style="font-family: Arial; font-weight: 700; color:<%- theming.colors.primaryTextColor %>;"
                                                          ><%- passwordless.oneTimeCode %>
                                                        </span></span
                                                      >
                                                    </div>
                                                    <div style="caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0)">
                                                      <p
                                                        style="
                                                          margin-bottom: 0;
                                                          margin-left: 0;
                                                          margin-right: 0;
                                                          margin-top: 30px;
                                                          padding-bottom: 0;
                                                          caret-color: rgb(0, 0, 0);
                                                        "
                                                      >
                                                        <span style="font-size: 16px; font-weight: 400; color:<%- theming.colors.primaryTextColor %>;"
                                                          ><span style="font-family: arial, helvetica, sans-serif"
                                                            >This code will expire in <%- passwordless.expirationLength %> minutes.
                                                          </span></span
                                                        >
                                                      </p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <table
                            align="left"
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="kmTextContentContainer"
                            style="
                              border-collapse: collapse;
                              mso-table-lspace: 0;
                              mso-table-rspace: 0;
                              table-layout: fixed;
                            "
                            width="100%"
                          >
                            <tbody>
                              <tr>
                                <td
                                  class="kmTextContent"
                                  style="
                                    border-collapse: collapse;
                                    mso-table-lspace: 0;
                                    mso-table-rspace: 0;
                                    table-layout: fixed;
                                    color: #222;
                                    font-family: 'Helvetica Neue', Arial;
                                    font-size: 14px;
                                    letter-spacing: 0;
                                    line-height: 1.3;
                                    max-width: 100%;
                                    text-align: left;
                                    word-wrap: break-word;
                                    padding-top: 30px;
                                    padding-bottom: 60px;
                                    padding-left: 60px;
                                    padding-right: 60px;
                                  "
                                  valign="top"
                                >
                                  <div data-pm-slice="1 1 []">
                                    <p
                                      data-pm-slice="1 1 []"
                                      style="
                                        margin-bottom: 0;
                                        margin-left: 0;
                                        margin-right: 0;
                                        margin-top: 0;
                                        text-align: center;
                                        padding-bottom: 1em;
                                        caret-color: rgb(0, 0, 0);
                                      "
                                    >
                                      <span style="font-size: 12px; font-weight: 400; color:<%- theming.colors.secondaryTextColor %>;"
                                        >Please email us at ${shopifyData.customer_email} or reply to this email if you have any
                                        questions.</span
                                      >
                                    </p>
                                  </div>
                                  <div style="caret-color: rgb(0, 0, 0); color: rgb(0, 0, 0)">
                                    <p
                                      style="
                                        margin-bottom: 0;
                                        margin-left: 0;
                                        margin-right: 0;
                                        margin-top: 0;
                                        padding-bottom: 0;
                                        caret-color: rgb(0, 0, 0);
                                        color: rgb(0, 0, 0);
                                      "
                                    >
                                      <span style="font-size: 16px"
                                        ><span style="font-family: arial, helvetica, sans-serif"></span
                                      ></span>
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                border="0"
                cellpadding="0"
                cellspacing="0"
                class="templateContainer brandingContainer"
                width="600"
                style="
                  border-collapse: collapse;
                  mso-table-lspace: 0;
                  mso-table-rspace: 0;
                  border: 0 none #aaa;
                  background-color: transparent;
                  border-radius: 0;
                "
              >
                <tbody>
                  <tr>
                    <td
                      class="templateContainerInner"
                      style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0; padding: 0"
                    >
                      <table
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                        width="100%"
                        style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                      >
                        <tr>
                          <td
                            align="center"
                            valign="top"
                            style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                          >
                            <table
                              border="0"
                              cellpadding="0"
                              cellspacing="0"
                              class="templateRow"
                              width="100%"
                              style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    class="rowContainer kmFloatLeft"
                                    valign="top"
                                    style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                                  >
                                    <table
                                      border="0"
                                      cellpadding="0"
                                      cellspacing="0"
                                      class="kmImageBlock"
                                      width="100%"
                                      style="border-collapse: collapse; mso-table-lspace: 0; mso-table-rspace: 0"
                                    >
                                      <tbody class="kmImageBlockOuter">
                                        <tr>
                                          <td
                                            class="kmImageBlockInner"
                                            style="
                                              border-collapse: collapse;
                                              mso-table-lspace: 0;
                                              mso-table-rspace: 0;
                                              padding: 9px;
                                              padding-top: 25px;
                                              padding-bottom: 25px;
                                            "
                                            valign="top"
                                          ></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </center>
  `);
}
