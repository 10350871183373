import { IShopifyData } from "../../../../ShopifyData";
import { passwordlessEmailFrame } from "../../../components/passwordlessFrame";

export function createMagicLinkEmailSubject(shopifyData: IShopifyData) {
  return `Log into ${shopifyData.name}`;
}
export function createMagicLinkEmailBody(shopifyData: IShopifyData) {
  return passwordlessEmailFrame(
    `
        <center>
            <table
                align="center"
                border="0"
                cellpadding="0"
                cellspacing="0"
                id="bodyTable"
                width="100%"
                style="
					border-collapse: collapse;
					mso-table-lspace: 0;
					mso-table-rspace: 0;
					padding: 0;
					background-color: #eee;
					height: 100%;
					margin: 0;
					width: 100%;
                    background-color: <%- theming.colors.backgroundColor %>
                    margin: 0 auto;
				"
            >
                <tbody>
                    <tr>
                        <td
                            align="center"
                            id="bodyCell"
                            valign="top"
                            style="
								border-collapse: collapse;
								mso-table-lspace: 0;
								mso-table-rspace: 0;
								padding-top: 50px;
								padding-left: 20px;
								padding-bottom: 20px;
								padding-right: 20px;
								border-top: 0;
								height: 100%;
								margin: 0;
								width: 100%;
							"
                        >
                            <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                class="templateContainer"
                                width="600"
                                style="
									border-collapse: collapse;
									mso-table-lspace: 0;
									mso-table-rspace: 0;
									border: 0 none #aaa;
									background-color: <%- theming.colors.backgroundColor %>;
									border-radius: 0;
								"
                            >
                                <tbody style="margin: 0 auto;">
                                    <tr>
                                        <td
                                            class="templateContainerInner"
                                            style="
												border-collapse: collapse;
												mso-table-lspace: 0;
												mso-table-rspace: 0;
												padding: 0;
											"
                                        >
                                            <table
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                style="
													border-collapse: collapse;
													mso-table-lspace: 0;
													mso-table-rspace: 0;
												"
                                            >
                                                <tr>
                                                    <td
                                                        align="start"
                                                        valign="top"
                                                        style="
															border-collapse: collapse;
															mso-table-lspace: 0;
															mso-table-rspace: 0;
														"
                                                    >
                                                        <table
                                                            border="0"
                                                            cellpadding="0"
                                                            cellspacing="0"
                                                            class="templateRow"
                                                            width="100%"
                                                            style="
																border-collapse: collapse;
																mso-table-lspace: 0;
																mso-table-rspace: 0;
															"
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <td
                                                                        class="rowContainer kmFloatLeft"
                                                                        valign="top"
                                                                        style="
																			border-collapse: collapse;
																			mso-table-lspace: 0;
																			mso-table-rspace: 0;
																		"
                                                                    >
                                                                        <table
                                                                            border="0"
                                                                            cellpadding="0"
                                                                            cellspacing="0"
                                                                            class="kmTextBlock"
                                                                            width="100%"
                                                                            style="
																				border-collapse: collapse;
																				mso-table-lspace: 0;
																				mso-table-rspace: 0;
																			"
                                                                        >
                                                                            <tbody
                                                                                class="kmTextBlockOuter"
                                                                            >
                                                                                <tr>
                                                                                    <td
                                                                                        class="kmTextBlockInner"
                                                                                        valign="top"
                                                                                        style="
																							border-collapse: collapse;
																							mso-table-lspace: 0;
																							mso-table-rspace: 0;
																						"
                                                                                    >
                                                                                        <table
                                                                                            align="left"
                                                                                            border="0"
                                                                                            cellpadding="0"
                                                                                            cellspacing="0"
                                                                                            class="kmTextContentContainer"
                                                                                            width="100%"
                                                                                            style="
																								border-collapse: collapse;
																								mso-table-lspace: 0;
																								mso-table-rspace: 0;
																							"
                                                                                        >
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        class="kmTextContent"
                                                                                                        valign="top"
                                                                                                        style="
																											border-collapse: collapse;
																											mso-table-lspace: 0;
																											mso-table-rspace: 0;
																											color:<%- theming.colors.primaryTextColor %>;
																											font-family: Arial;
																											font-size: 16px;
																											line-height: 19px;
																											text-align: start;
																											padding-top: 60px;
																											padding-left: 60px;
																											padding-right: 60px;
																										"
                                                                                                    >
                                                                                                        <p>
                                                                                                            Click
                                                                                                            the
                                                                                                            button
                                                                                                            below
                                                                                                            to
                                                                                                            sign
                                                                                                            into
                                                                                                            your
                                                                                                            ${shopifyData.name}
                                                                                                            account,
                                                                                                            no
                                                                                                            password
                                                                                                            required.
                                                                                                            This
                                                                                                            magic
                                                                                                            link
                                                                                                            will
                                                                                                            expire
                                                                                                            in
                                                                                                            <%- passwordless.expirationLength %>
                                                                                                            minutes.
                                                                                                        </p>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table
                                                                            border="0"
                                                                            cellpadding="0"
                                                                            cellspacing="0"
                                                                            class="kmTableBlock kmTableMobile"
                                                                            width="100%"
                                                                            style="
																				border-collapse: collapse;
																				mso-table-lspace: 0;
																				mso-table-rspace: 0;
																			"
                                                                        >
                                                                            <tbody
                                                                                class="kmTableBlockOuter"
                                                                            >
                                                                                <tr>
                                                                                    <td
                                                                                        class="kmTableBlockInner"
                                                                                        valign="top"
                                                                                    >
                                                                                        <table
                                                                                            align="left"
                                                                                            border="0"
                                                                                            cellpadding="0"
                                                                                            cellspacing="0"
                                                                                            class="kmTextContentContainer"
                                                                                            style="
																								border-collapse: collapse;
																								mso-table-lspace: 0;
																								mso-table-rspace: 0;
																								table-layout: fixed;
																							"
                                                                                            width="100%"
                                                                                        >
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        class="kmButtonContainer"
                                                                                                        style="
																											border-collapse: collapse;
																											mso-table-lspace: 0;
																											mso-table-rspace: 0;
																											table-layout: fixed;
																											max-width: 100%;
																											text-align: start;
																											word-wrap: break-word;
																											height: 50px;
                                                                                                            padding: 40px 60px 10px;
																										"
                                                                                                        valign="top"
                                                                                                    >
                                                                                                        <div
                                                                                                            data-pm-slice="1 1 []"
                                                                                                            style="margin-bottom: 10px;"
                                                                                                        >
                                                                                                            <a href='<%- passwordless.loginUrl %>' style="
                                                                                                                text-decoration:none !important;
                                                                                                                cursor:pointer;
                                                                                                                border: none;
                                                                                                                padding: 15px 30px;
                                                                                                                border-radius: 50px;
                                                                                                                background: <%- theming.colors.buttonBackgroundColor%>;
                                                                                                                color: <%- theming.colors.buttonTextColor%>;
                                                                                                                font-size: 16px;
                                                                                                                font-family: Inter, sans-serif;
                                                                                                                font-weight: 500;
                                                                                                                line-height: 19px;
                                                                                                                width: max-content;
                                                                                                            ">
                                                                                                                Log
                                                                                                                into
                                                                                                                ${shopifyData.name}
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        style="
																											border-collapse:collapse;
                                                                                                            table-layout:fixed;
                                                                                                            max-width:100%;
                                                                                                            text-align:start;
                                                                                                            word-wrap:break-word;
                                                                                                            padding-bottom:0;
                                                                                                            padding-left:60px;
                                                                                                            padding-right:60px;"
                                                                                                    >
                                                                                                        <p
                                                                                                            style="
																													margin-bottom: 0;
																													margin-left: 0;
																													margin-right: 0;
																													padding-bottom: 0;
																													caret-color: rgb(
																														0,
																														0,
																														0
																													);
																													color: rgb(
																														0,
																														0,
																														0
																													);
																												"
                                                                                                        >
                                                                                                            <span
                                                                                                                style="
																														font-size: 16px;
																														font-weight: 400;
																													"
                                                                                                            ><span
                                                                                                                style="
																															font-family: arial,
																																helvetica,
																																sans-serif;
																															line-height: 16px;
                                                                                                                            color:<%- theming.colors.primaryTextColor %>;
																														"
                                                                                                            >This
                                                                                                                    magic
                                                                                                                    link
                                                                                                                    will
                                                                                                                    expire
                                                                                                                    in
                                                                                                                    <%- passwordless.expirationLength %>
                                                                                                                    minutes.
                                                                                                                </span></span
                                                                                                            >
                                                                                                        </p>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <table
                                                        align="left"
                                                        border="0"
                                                        cellpadding="0"
                                                        cellspacing="0"
                                                        class="kmTextContentContainer"
                                                        style="
															border-collapse: collapse;
															mso-table-lspace: 0;
															mso-table-rspace: 0;
															table-layout: fixed;
														"
                                                        width="100%"
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                    class="kmTextContent"
                                                                    style="
																		border-collapse: collapse;
																		mso-table-lspace: 0;
																		mso-table-rspace: 0;
																		table-layout: fixed;
																		color: #222;
																		font-family: 'Helvetica Neue',
																			Arial;
																		font-size: 14px;
																		letter-spacing: 0;
																		line-height: 1.3;
																		max-width: 100%;
																		text-align: left;
																		word-wrap: break-word;
																		padding-top: 30px;
																		padding-bottom: 60px;
																		padding-left: 60px;
																		padding-right: 60px;
																	"
                                                                    valign="top"
                                                                >
                                                                    <div
                                                                        data-pm-slice="1 1 []"
                                                                    >
                                                                        <p
                                                                            data-pm-slice="1 1 []"
                                                                            style="
																				margin-bottom: 0;
																				margin-left: 0;
																				margin-right: 0;
																				margin-top: 0;
																				text-align: start;
																				padding-bottom: 1em;
																			"
                                                                        >
                                                                            <span
                                                                                style="
																					font-size: 12px;
																					font-weight: 400;
																					color:<%- theming.colors.secondaryTextColor %>;
																				"
                                                                            >Clicking this button will securely log you in as
                                                                                <span
                                                                                    style="
																						font-weight: 600;
                                                                                        text-decoration: none !important;
                                                                                        color: inherit !important;
																					"
                                                                                >
                                                                                    <%- customer.email %></span>. Didn't
                                                                                request
                                                                                this
                                                                                link?
                                                                                You
                                                                                can
                                                                                safely
                                                                                ignore
                                                                                this
                                                                                message.</span
                                                                            >
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        style="
																			caret-color: rgb(
																				0,
																				0,
																				0
																			);
																			color: rgb(
																				0,
																				0,
																				0
																			);
																		"
                                                                    >
                                                                        <p
                                                                            style="
																				margin-bottom: 0;
																				margin-left: 0;
																				margin-right: 0;
																				margin-top: 0;
																				padding-bottom: 0;
																				caret-color: rgb(
																					0,
																					0,
																					0
																				);
																				color: rgb(
																					0,
																					0,
																					0
																				);
																			"
                                                                        >
                                                                            <span
                                                                                style="
																					font-size: 16px;
																				"
                                                                            ><span
                                                                                style="
																						font-family: arial,
																							helvetica,
																							sans-serif;
																					"
                                                                            ></span
                                                                                ></span>
                                                                        </p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                                class="templateContainer brandingContainer"
                                width="600"
                                style="
									border-collapse: collapse;
									mso-table-lspace: 0;
									mso-table-rspace: 0;
									border: 0 none #aaa;
									background-color: transparent;
									border-radius: 0;
								"
                            >
                                <tbody>
                                    <tr>
                                        <td
                                            class="templateContainerInner"
                                            style="
												border-collapse: collapse;
												mso-table-lspace: 0;
												mso-table-rspace: 0;
												padding: 0;
											"
                                        >
                                            <table
                                                border="0"
                                                cellpadding="0"
                                                cellspacing="0"
                                                width="100%"
                                                style="
													border-collapse: collapse;
													mso-table-lspace: 0;
													mso-table-rspace: 0;
												"
                                            >
                                                <tr>
                                                    <td
                                                        align="start"
                                                        valign="top"
                                                        style="
															border-collapse: collapse;
															mso-table-lspace: 0;
															mso-table-rspace: 0;
														"
                                                    >
                                                        <table
                                                            border="0"
                                                            cellpadding="0"
                                                            cellspacing="0"
                                                            class="templateRow"
                                                            width="100%"
                                                            style="
																border-collapse: collapse;
																mso-table-lspace: 0;
																mso-table-rspace: 0;
															"
                                                        >
                                                            <tbody>
                                                                <tr>
                                                                    <td
                                                                        class="rowContainer kmFloatLeft"
                                                                        valign="top"
                                                                        style="
																			border-collapse: collapse;
																			mso-table-lspace: 0;
																			mso-table-rspace: 0;
																		"
                                                                    >
                                                                        <table
                                                                            border="0"
                                                                            cellpadding="0"
                                                                            cellspacing="0"
                                                                            class="kmImageBlock"
                                                                            width="100%"
                                                                            style="
																				border-collapse: collapse;
																				mso-table-lspace: 0;
																				mso-table-rspace: 0;
																			"
                                                                        >
                                                                            <tbody
                                                                                class="kmImageBlockOuter"
                                                                            >
                                                                                <tr>
                                                                                    <td
                                                                                        class="kmImageBlockInner"
                                                                                        style="
																							border-collapse: collapse;
																							mso-table-lspace: 0;
																							mso-table-rspace: 0;
																							padding: 9px;
																							padding-top: 25px;
																							padding-bottom: 25px;
																						"
                                                                                        valign="top"
                                                                                    ></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </center>
        `
  );
}
