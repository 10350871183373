import {
  createBasicDefaultEmailConfigPartial,
  createDefaultEmailConfig,
  ProductUnavailableReason,
} from "@smartrr/shared/entities/CustomerEmailConfig";
import { dummyEmailData, ICustomerEmailDummy } from "@smartrr/shared/entities/CustomerEmailConfig/mocks";
import { OrganizationIntegrationId } from "@smartrr/shared/entities/Integration";
import { toViewDate, toViewLongDate } from "@smartrr/shared/utils/renderViewDate";
import type { RequireNilFields } from "@smartrr/shared/utils/RequireNilFields";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";

import { loadIntegrations } from "@vendor-app/app/_state/actionCreators/organization";
import {
  useActiveOrganizationSelector,
  useActiveOrganizationSubSelector,
} from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { getDummyLineItems } from "../utils";
import {
  ICustomerEmailConfig,
  ICustomerEmailTheming,
  IEmailPreview,
  IFormValues,
} from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { purchasableSnowboardOxygen } from "@smartrr/shared/tests/mocks/purchasableMocks/basic";
import { useSmartrrFlags } from "@smartrr/shared/LaunchDarkly";
import { ThemingStoreAccess } from "@vendor-app/app/AdminRoute/AdminThemingRoute/store";

export const useEmailContext = (formValues: IFormValues): ICustomerEmailDummy => {
  const defaultEmailConfig = useDefaultEmailConfig();
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);
  const activeOrg = useActiveOrganizationSelector();
  const dummyLines = getDummyLineItems(purchasables.length ? purchasables : [purchasableSnowboardOxygen]);
  const { currentThemeValues } = ThemingStoreAccess.useThemeProperties();

  const locale = currentThemeValues?.translationOverrides?.activeLanguage;

  let emailTheming: ICustomerEmailTheming;
  let upcomingOrderEmailDeliveryDelay = 0;

  if (formValues && (formValues as ICustomerEmailTheming).useStyling) {
    emailTheming = formValues as ICustomerEmailTheming;
  } else if (formValues && (formValues as ICustomerEmailConfig).emailTheming) {
    emailTheming = (formValues as ICustomerEmailConfig).emailTheming ?? defaultEmailConfig.emailTheming;
    upcomingOrderEmailDeliveryDelay = (formValues as ICustomerEmailConfig).upcomingOrderEmailDeliveryDelay ?? 0;
  } else {
    emailTheming = defaultEmailConfig.emailTheming;
  }

  const todayPlusDelayOffset = DateTime.now().plus({
    days: upcomingOrderEmailDeliveryDelay! > 0 ? upcomingOrderEmailDeliveryDelay : 1,
  });

  return {
    ...dummyEmailData,
    subscription: {
      ...dummyEmailData.subscription,
      lines: dummyLines,
      daysTillDelivery: upcomingOrderEmailDeliveryDelay!,
      daysTillUnpause: dummyEmailData.subscription.daysTillUnpause,
      unpauseDate: toViewDate(dummyEmailData.subscription.unpauseDate, {
        month: "long",
        day: "numeric",
        year: "numeric",
        locale,
      }),
      deliveryDate: toViewLongDate(todayPlusDelayOffset, locale),
      nextBillingDate: toViewDate(dummyEmailData.subscription.nextBillingDate, {
        day: "2-digit",
        month: "long",
        year: "numeric",
        locale,
      }),
    },
    outOfStockItem: {
      ...dummyLines[0].product,
      name: dummyLines[0].product.name,
      variantName: purchasables.find(p => p.id === dummyLines[0].product.id)?.vnts?.[0].purchasableVariantName!,
      unavailableReason: ProductUnavailableReason.OUT_OF_STOCK,
      image: dummyLines[0].variant.image,
    },
    theming: {
      ...emailTheming,
    },
    passwordless: {
      ...dummyEmailData.passwordless,
      loginUrl: activeOrg ? `https://${activeOrg.myShopifyDomain}/account/login` : "",
    },
  };
};

export const useDefaultEmailConfig = (): RequireNilFields<ICustomerEmailConfig> => {
  const { setup } = useSmartrrVendorSelector(state => state.vendorOrganizations);
  const orgShopifyData = useActiveOrganizationSubSelector(state => state?.shopifyStoreData);
  const { notificationEmailRefactor } = useSmartrrFlags(); // TODO: Remove when full refactor is complete
  const [emailConfig, setEmailConfig] = useState<RequireNilFields<ICustomerEmailConfig>>(
    createBasicDefaultEmailConfigPartial(orgShopifyData!, setup)
  );

  useEffect(() => {
    void (async () => {
      setEmailConfig(await createDefaultEmailConfig(orgShopifyData!, setup, notificationEmailRefactor));
    })();
  }, []);

  return emailConfig;
};

export const useApiKeys = () => {
  const [apiKeys, setApiKeys] = useState<{ [key in OrganizationIntegrationId]?: string }>({});
  const { activeOrganizationIntegrations: activeIntegrations, isLoadingIntegrations } = useSmartrrVendorSelector(
    state => state.vendorOrganizations
  );

  const dispatch = useSmartrrVendorDispatch();
  useEffect(() => {
    if (activeIntegrations) {
      const keys = activeIntegrations.reduce(
        (acc, integration) => {
          acc[integration.integrationId] = integration.apiKey;
          return acc;
        },
        {} as { [key in OrganizationIntegrationId]: string }
      );
      setApiKeys(keys);
    }
  }, [activeIntegrations]);

  useEffect(() => {
    dispatch(loadIntegrations());
  }, []);

  return { apiKeys, isLoadingIntegrations };
};

export const useEmailPreviewHTML = ({
  currBody,
  defaultsEnabled,
  disableHeaderAndFooter = false,
  manipulatedEmailConfigData,
}: {
  manipulatedEmailConfigData: IEmailPreview;
  defaultsEnabled: boolean;
  disableHeaderAndFooter: boolean | undefined;
  currBody: string;
}) => {
  const defaultEmailConfig = useDefaultEmailConfig();
  return useMemo(() => {
    if (!defaultsEnabled) {
      return currBody;
    }

    if (!disableHeaderAndFooter) {
      return (
        defaultEmailConfig.emailHeader! +
        defaultEmailConfig[manipulatedEmailConfigData.configValues?.body!] +
        defaultEmailConfig.emailFooter
      );
    }
    return String(defaultEmailConfig[manipulatedEmailConfigData.configValues?.body!]);
  }, [disableHeaderAndFooter, currBody, defaultEmailConfig, manipulatedEmailConfigData]);
};
