import { ComplexAction, FormLayout, Modal } from "@shopify/polaris";
import React, { useMemo } from "react";
import { useEmailPreviewModalStore } from "./lib/useEmailPreviewModalStore";
import { template } from "lodash";
import { useToast } from "../../Toast/ToastProvider";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import {
  useEmailContext,
  useEmailPreviewHTML,
} from "@vendor-app/app/AdminRoute/AdminSubscriberEngagementRoute/EmailSettings/components/libs/hooks";
import { IEmailPreview, IFormValues } from "@smartrr/shared/entities/CustomerEmailConfig/types";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { EmailPreviewEmailInput } from "./lib/EmailPreviewEmailInput";
import { EmailPreviewWrapper, WideModalWrapper } from "./lib/constants";

export interface EmailPreviewModalProps {
  /**
   * Email obj being previewed
   */
  emailPreview: IEmailPreview;

  /**
   * Input field values (if any) for rendering custom email
   * TODO: Create new type, this is repeated
   */
  formValues: IFormValues;

  /**
   * `true` - Page is only showing default email configs, modal will tack on default header and footer to email (unless specified not to do so with `disableHeaderAndFooter`)
   *
   * `false` - Page is showing custom emails, modal will not applied header and footer
   */
  defaults: boolean;

  /**
   * For some emails we don't want the merchant to send a preview email to themselves
   * and to only view the email in-app. Passing this prop changes the preview button state.
   *
   * Use in conjunction with `useIsSuperUserSelector()`
   */
  ableToSendPreview?: boolean;
}

export const EmailNotificationPreviewModal = () => {
  const payload = useEmailPreviewModalStore(state => state.modalPayload);
  const emailPreview = payload?.emailPreview;
  if (emailPreview) {
    return <EmailNotificationPreviewModalInner emailPreview={emailPreview} />;
  }
  return <React.Fragment />;
};

const EmailNotificationPreviewModalInner = ({ emailPreview }: { emailPreview: IEmailPreview }) => {
  const { addToast } = useToast();
  const isEmailPreviewModalOpen = useEmailPreviewModalStore(state => state.isModalOpen);
  const closeEmailPreviewModal = useEmailPreviewModalStore(state => state.closeModal);
  const payload = useEmailPreviewModalStore(state => state.modalPayload);
  const context = useEmailContext(payload?.formValues);
  const recipientEmail = useSmartrrVendorSelector(state => state.auth.user?.originalEmail);

  const canSendEmailPreview = useMemo(
    () => payload?.ableToSendPreview && recipientEmail,
    [payload?.ableToSendPreview, recipientEmail]
  );

  // If an email or is a new react email or a custom email we don't want the header or footer applied
  // and instead rely on what's provided by the inputted HTML
  const disableHeaderAndFooter = emailPreview.disableHeaderAndFooter ?? !payload?.defaults;

  const previewHtml = useEmailPreviewHTML({
    manipulatedEmailConfigData: emailPreview,
    disableHeaderAndFooter: disableHeaderAndFooter ?? false,
    defaultsEnabled: payload?.defaults ?? true,
    currBody: emailPreview.emailBody ?? "",
  });

  const sendPreviewEmail = async () => {
    if (!recipientEmail) {
      addToast(`Unable to send email, please contact support`, true);
      return;
    }
    const res = await typedFrontendVendorApi.postReq("/customer-email-preview", {
      reqBody: {
        subject: emailPreview.emailSubject,
        htmlBody: emailPreview.emailBody,
        disableHeaderAndFooter,
        context,
      },
    });

    if (res.type === "error") {
      addToast(`Error sending email`);
    } else if (res.type === "success") {
      addToast(`Email sent successfully`);
      closeEmailPreviewModal();
    }
  };

  const modalFooterAction: ComplexAction = canSendEmailPreview
    ? {
        content: "Send email preview",
        onAction: sendPreviewEmail,
      }
    : {
        content: "Close preview",
        onAction: closeEmailPreviewModal,
      };

  const templatedHTMLToRender = useMemo(() => template(previewHtml)(context), [previewHtml, context]);

  return (
    <WideModalWrapper>
      <Modal
        fullScreen
        large
        title="Email Preview"
        open={isEmailPreviewModalOpen}
        onClose={() => {
          closeEmailPreviewModal();
        }}
        primaryAction={modalFooterAction}
      >
        <Modal.Section>
          <FormLayout>
            {canSendEmailPreview ? (
              <EmailPreviewEmailInput email={recipientEmail!} subject={emailPreview.emailSubject ?? ""} />
            ) : (
              <React.Fragment />
            )}
            <EmailPreviewWrapper modal>
              <div
                dangerouslySetInnerHTML={{
                  __html: templatedHTMLToRender,
                }}
              />
            </EmailPreviewWrapper>
          </FormLayout>
        </Modal.Section>
      </Modal>
    </WideModalWrapper>
  );
};
